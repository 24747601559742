#labelStock {
    display: flex;
    color: white;
    font-size: 1.2rem;
    font-weight: bolder;
  }
  
  #labelAnimal {
    width: 100px;
    outline: none;
    border: none;
    background: #b46739;
  }
  
  #labelLote {
    width: 100px;
    outline: none;
    border: none;
    background: #b46739;
  }
  
  #labelProduto {
    width: 100px;
    outline: none;
    border: none;
    background: #b46739;
  }
  /* ESTOQUE PRODUTO */
  div.fundo-fixa-produto{
      background: #45424263;
    }
  
  @media (max-width: 390px) {
    #labelStock {
      display: inline-block;
    }
  }