.tabela {
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-collapse: collapse;
}

.tabela td {
  padding: 10px 14px;
  border: 1px solid black;
}

.tabela tr td.Artigo,
.tabela td.Criterio:nth-child(even) {
  background: #f5f5f5;
}

.tabela tr.pp {
  height: 45px;
}

.tabela tr {
  border: 1px solid;
}

.tabela td.texto-principal {
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
}

.tabela td.Artigo,
.tabela td.Criterio {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 50px;
  font-weight: 100;
}

.tabela tr.categorias td {
  text-align: center;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  padding: 7px;
  width: 80px;
}

.tabela tr.categorias td.largo {
  width: 250px;
}

.tabela tr.preenchimento td {
  text-align: left;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
}

/**/

.btns-estados-carrinho {
  padding: 10px;
  width: 100%;
  margin: 10px;
  border-radius: 7px;
  display: flex;
}

@media(max-width:700px) {
  .btns-estados-carrinho {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
  }

  .btns-estados-carrinho button,
  .btns-estados-carrinho a {
    margin-top: 5px;
    width: 200px;
  }
}


a.btn-negar {
  border: 1px solid black;
  outline: none;
  font-weight: bold;
  margin-left: 20px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.6s ease-in;
  position: relative;
  color: #fff;
}

@keyframes animate {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

a.btn-negar:hover {
  background: #9b0b0b;
  color: #fff;
  border: 1px solid #fff;
  text-shadow: 0 0 50px #fa0202,
               0 0 100px #fa0202,
               0 0 200px #fa0202,
               0 0 250px #fa0202;
  animation: animate 4s linear infinite;
}

/* Responsive table styles */
.responsiveTable {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.responsiveTable caption {
  font-size: 1rem;
  /* margin: 0.5rem 0 0.75rem; */
}

.responsiveTable tr {
  border-bottom: 1px solid #d1d1d161;
  /* padding: 0.5rem; */
}

.responsiveTable th,
.responsiveTable td {
  /* padding: 0.625rem; */
  text-align: left;
}

.responsiveTable th {
  font-size: 1rem;
  font-weight: 700;
  border-bottom: 2px solid #8f8f8f6e;
}

@media screen and (max-width: 999px) {

  .responsiveTable {
    border: 0;
  }


  .responsiveTable caption {
    font-size: 1rem;
  }

  .responsiveTable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .responsiveTable tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625rem;
    padding: 0;
  }

  .responsiveTable td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1rem;
    text-align: right;
  }

  .responsiveTable td::before {
    /*************************************************************************
      ** aria-label has no advantage, it won't be read inside a .responsiveTable
      content: attr(aria-label);================================================
      **************************************************************************/
    content: attr(data-label);
    float: left;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  .responsiveTable td:last-child {
    border-bottom: 0;
  }
}

span.link-add {
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


.link-add-print {
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.link-add-plus {
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}