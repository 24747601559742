 
img.icon{
    width: 30px;  
  } 
  
  p.texto-grafico{ 
    padding: 10px;
    text-align: left;
  }
  
  span.cor-bovino{
    background: #9ba429;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  
  span.cor-suino{
    background: #424616;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  span.cor-caprino{
    background: #9ba236c9;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  h4.descricao{
    color: #9ba236;
    font-size: 1.5rem; 
    padding: 10px;
    font-weight: 100;
  }
  
  /*Animais leiluados*/
  
  div.distancia{
    margin: 10px;
  }
  
  span.cor-bovino-leiluado{
    background: #76f024;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  span.cor-ovino-leiluado{
    background: #acb43994;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  span.cor-caprino-leiluado{
    background: #444911;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  span.cor-esquideo-leiluado{
    background: #181a02;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  span.cor-suino-leiluado{
    background: #7e852494;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  span.cor-asinino-leiluado{
    background: #252706;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  span.cor-buffalo-leiluado{
    background: #181a02;
    padding: 2px; 
    margin: 15px;
    color: transparent;
  }
  
  .text{
    background: #5ad806d0;
  }

 
  .barHome{
      width: 800px;
      height: 500px;
  }

  /*BTN PESQUISA*/

  input.pesquisa{
    border: 1px solid #9ba236;
    border-radius: 24px;
    width: 270px;
    padding: 6px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
 

 .relatorios_txt{
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
    display: grid;
    justify-content: center;
    align-items: center;
    color: #505050;
  }

  .bg-Login{
    background: url(../assets/img/avatar/GD-1_Prancheta\ 1.png);
    background-position: center;
    background-size: cover;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
  }


/*STOCK*/

div.fundo-fixa-animal{
  background: #45424263;
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; 
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

