/*Mensagens Recebidas*/

p.msg-recebidas{
    color: #006eff;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .message{
    width: 280px;
    max-height: 100%;
  
  }
  
  div.message p.nome_da_pessoa{
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
  
    color: #006eff;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    letter-spacing: 1px;
    line-height: 14px;
  }
  
  div.message p.nome_da_pessoa:hover{
    color: #000;
    cursor: pointer; 
  }
  
  div.media-body{
    margin-left: 70px;
  }
  
  span.img_mensagem img.fa-user-circle{
    padding-right: 120rem; 
  
  }
   
  
  Link{
   font-Family: Arial, Helvetica, sans-serif;
  }
   
  
  /*NOTOFICAÇÕES*/
  
  div.novas-notificacoes{
    width: 280px;
    max-height: 100%;
    color: #9ba236;
  }
 
  p.notf-recebidas{
    color: #99a325;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
  }

/*Notificacoes*/

span.nova-notificacao{
    border-radius: 50%;
    color: #fff;
    font-weight: bolder;
  }  
  
  .icon-not{
    background: #9ba236;
    border-radius: 50%;
    width: 35px;
    padding: 7px;
    color: white;
    text-align: center;
    margin: 10px;    
    position: absolute; 
  }  

  .list-notification ul{
    background: #cccccc52;
    padding: 10px;
    border-radius: 15px;   
    cursor: pointer;
  } 

  .list-notification ul li{
    list-style: none;
    font-size: 0.8rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 70px;
  }  

  li#conteudo{
    font-weight: 100;
  }

  li#data{
    color: #99a325;
    display: flex;
  }