/* .container #custom-btn{ */
.custom-btn {
  padding: 1rem;
  text-align: center;
  margin-top: 3px;
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;

  cursor: pointer;
  background: linear-gradient(135deg, #9ba236 0%, #99a325 100%);
}

.foto-cooperador{
  border-radius: 6px;
}


link.dropdown-toggle{ 
  font-family: Arial, Helvetica, sans-serif;
}

li.menu-title{
  font-family: Arial, Helvetica, sans-serif;
}

.stat-heading{
  font-family: Arial, Helvetica, sans-serif;
}
 
.texto-grafico{
  font-family: Arial, Helvetica, sans-serif;
}


.user-menu a.nav-link{
  font-family: Arial, Helvetica, sans-serif;   
}


.btn-primary{
  background: #9ba236;
  border: none;
}

.btn-warning{
  background:  #b46739;
  border: none;
}



.btn-primary:hover{
  background: #9ba236;
}

.btn-warning:hover{
  background:  #b46739;
}

/*Mensagens Recebidas*/

p.msg-recebidas{
  color: #006eff;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.message{
  width: 280px;
  max-height: 100%;

}

div.message p.nome_da_pessoa{
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;

  color: #006eff;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  letter-spacing: 1px;
  line-height: 14px;
}

div.message p.nome_da_pessoa:hover{
  color: #000;
  cursor: pointer; 
}

div.media-body{
  margin-left: 70px;
}

span.img_mensagem img.fa-user-circle{
  padding-right: 120rem; 

}
 

Link{
 font-Family: Arial, Helvetica, sans-serif;
}
 

/*NOTOFICAÇÕES*/

div.novas-notificacoes{
  width: 280px;
  max-height: 100%;
  color: #9ba236;
}

p.notf-recebidas{
  color: #006eff;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

p.notf-recebidas{
  color: #99a325;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}