#inicio-loader{
  color: white;
   width: 100%;
  height: 100%; 
  text-align: center;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 190px;
}

.loader{
  width: 5rem;
  height: 5rem;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin-bottom: 3rem;
  margin-bottom: -500px;
  background: url("../../src/assets/img/logo/logo1.jpg");
  background-position: center;
  background-size: cover;
}


@keyframes spin {
  to{
      transform: rotate(360deg);
  }
}

.loading > div{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  animation: 1.5s bola-0 infinite ease-in-out both;
  margin: 0 auto;
}

.bola-0{
  background: #9ba235;
  color: #fff;
  width: 14px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}
.bola-1{
  background: #b46739;
  color: #fff;
  width: 14px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}
.bola-2{
  background: #9ba235;
  color: #fff;
  width: 14px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}
.loading .bola-0{
  animation-delay: -0.30s;
}

.loading .bola-1{
  animation-delay: -0.15s;
}

@keyframes bola-0{
  0%, 8%, 100%{
      transform: scale(0);
  }

  40%{
      transform: scale(1);
  }   
}