*{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
  .preview {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .image {
    max-width: 100%;
    max-height: 320px;
  }
  .delete {
    cursor: pointer;
    padding: 15px;
    background: red;
    color: white;
    border: none;
  }

  .IMGList{
    width: 50px;
    border-radius: 5px;
  }
  .IMGListBig{
    width: 100px;
    border-radius: 15px;
  }
  .userIMGListBig{
    width: 50px;
    height: 50px;
    border-radius: 100px;
  }
  