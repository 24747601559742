  /*  */
  .modalO {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;

  }


  #modal-rapido {
    width: 400px;
  }


  /*para o btn do estado do participante do leilao*/

  button.btn-estado {
    background: #b46739;
    padding: 7px;
    width: 140px;
    border: none;
    border-radius: 7px;
    color: white;
    transition: 0.6s all ease-in-out;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
  }

  button.btn-estado:hover {
    background: #63381f;
  }


  div#btns-estado {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  a.btn-app {
    background: #12b212;
    margin: 5px;
    color: white;
    border: none;
    outline: none;
  }
 
  a.btn-rr {
    width: 70%;
    padding: 7px;
    background: #b22a12;
    margin: 5px;
    color: white;
    border: none;
    outline: none;
  }

  button#estado-pendente {
    width: 85%;
    padding: 7px;
    background: #d5a918;
    color: white;
    border-radius: 7px;
    cursor: none;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    outline: none;
  }

  button#estado-reprovado {
    width: 85%;
    padding: 7px;
    background: #d52b18;
    color: white;
    border-radius: 7px;
    cursor: none;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    outline: none;
  }

  button#estado-aprovado {
    width: 85%;
    padding: 7px;
    background: #299308;
    color: white;
    border-radius: 7px;
    cursor: none;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    outline: none;
  }

  /**/

  .dd-eri {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 's-1 s-2';
  }

  strong#s-1 {
    grid-area: s-1;
    display: flex;
  }

  strong#s-2 {
    grid-area: s-2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  button.open-comprov {
    background: #63381f;
    padding: 7px;
    width: 60px;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-radius: 7px;
    color: white;
    cursor: pointer;
  }


  .info-licitador {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }



  /*formatação da responsividade dos botões de aprovação*/

  @media(max-width:999px) {


    button#estado-pendente {
      width: 100px;
    }

    button#estado-reprovado {
      width: 100px;
    }

    button#estado-aprovado {
      width: 100px;
    }
    
  }


  /**/

.info-licitador{
  background: #6b7024;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.info-licitador td, .info-licitador th{
  margin: 10px;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.infor-to-ad{
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px;
}
/**/

@media(max-width:999px){
  .info-licitador td{
    text-align: right;
  }
}