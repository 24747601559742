.Formatacao-Relatorio {
  display: flex;
  flex-direction: column;
  width: 960px;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
/*  */
.App_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  color: black;
  font-size: 1.1rem;
}

.data_cab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  width: 100%;
}

.data_esq {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 10px 15px;
}

.data_esq img {
  max-width: 35%;
}

.data_dir {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  width: 100%;
  padding: 10px 15px;
}

.App_h1 {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

h4.txt-centro {
  font-weight: 100;
  font-family: Arial, Helvetica, sans-serif;
}

.App_body {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 25px;
}

.data_total {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  padding: 10px 15px;

  height: 100%;
  width: 100%;
  flex-direction: column;
}

.data_funny {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-left: 730px;
}

.botao {
  padding: 10px 16px;
  margin-top: 15px;
  font-size: 14px;
  background-color: transparent;
  border-radius: 6px;
  font-weight: bold;
  transition: ease-in 0.3s;
  margin: 0 5px;
  cursor: pointer;
}
.botao_print {
  border: 1px solid #9ba236;
  color: black;
  margin: 5px;
}
.botao_print:hover {
  background-color: #9ba236;
  color: #fff;
}
.botao_return {
  border: 1px solid #d89039;
  color: black;
  margin: 5px;
}

.botao_return:hover {
  background-color: #d89039;
  color: #fff;
}

.table {
  border-collapse: collapse;
  margin: auto;
  max-width: 100%;
}

.table,
.th,
.td {
  border-radius: 1px;
}
.th,
.td {
  padding: 10px;
  text-align: center;
}

/* MEDIA QUERIES */
/* @media all and (min-width: 768px) {} */
@media (min-width: 768px) {
  .Formatacao-Relatorio {
    display: flex;
    flex-direction: column;
    width: 960px;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .App_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    color: black;
    font-size: 1.1rem;
  }
  .data_esq {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 100%;
    padding: 10px 15px;
  }
  .data_esq img {
    max-width: 35%;
  }
  .data_dir {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
    width: 100%;
    padding: 10px 15px;
  }
}
